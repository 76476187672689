html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

#root > header {
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 2em;
  color: white;
  background-color: cornflowerblue;
}

#root > main {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
}

#root > footer {
  padding: 1rem;
  color: white;
  background-color: #333;
}

#root > footer a {
  color: yellow;
}
